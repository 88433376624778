import { mapActions, mapGetters } from 'vuex';

//---helpers
import { BLOG_NEWS_TYPE } from '@/helpers';
import { globalLoader } from '@/helpers/variables';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import Pagination from '@/components/@general/Pagination/index.vue';

export default {
  name: 'News',
  mixins: [dynamicHead],
  components: {
    BreadcrumbsSimple,
    Pagination
  },
  props: {},
  data() {
    return {
      newsType: BLOG_NEWS_TYPE.INTERESTING,
      newsList: [],
      newsListMeta: null,
      isNewsListLoading: false
    };
  },
  created() {
    console.log('NEWS', this.$route);
    let { name } = this.$route;
    if (name.indexOf(BLOG_NEWS_TYPE.NEWS) > -1) {
      this.newsType = BLOG_NEWS_TYPE.NEWS;
    }
    this._loadContent();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters({
      metaData: 'meta/metaData'
    }),
    pageTitle() {
      return this.newsType === BLOG_NEWS_TYPE.NEWS ? this.$t('page.news.title') : this.$t('page.interesting.title');
    },
    paginationRootPath() {
      return this.newsType === BLOG_NEWS_TYPE.NEWS ? 'news' : 'interesnye-stati';
    }
  },
  methods: {
    ...mapActions({
      getBlogNews: 'blog/GET_BLOG_NEWS',
      getBlogInterestingArticles: 'blog/GET_BLOG_INTERESTING_ARTICLES',
      getMetaData: 'meta/GET_META_DATA'
    }),
    async _getNewsList() {
      this.isNewsListLoading = true;
      let { page } = this.$route.params;
      let result = null;
      if (this.newsType === BLOG_NEWS_TYPE.NEWS) {
        result = await this.getBlogNews({ page: page ? Number(page) : 1 });
      } else if (this.newsType === BLOG_NEWS_TYPE.INTERESTING) {
        result = await this.getBlogInterestingArticles({ page: page ? Number(page) : 1 });
      }

      this.newsList = result.data;
      this.newsListMeta = result.meta;
      this.isNewsListLoading = false;
    },
    async _loadContent() {
      globalLoader(true);
      let promiseCollection = [];
      promiseCollection.push(this._getNewsList());
      promiseCollection.push(
        this.getMetaData(this.paginationRootPath).then(() => {
          this._setMetaParams();
        })
      );
      await Promise.all(promiseCollection);
      globalLoader(false);
    },
    _setMetaParams() {
      let { metaTitle, metaDescription, image } = this.metaData;
      if (metaTitle) this.metaParams.title = `${metaTitle}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    }
  }
};
